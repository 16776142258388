export const dialogQrDic = [
	'android',
	'ios'
];

export const dialogOsCards = [
	{
		label: 'Windows',
		value: 'windows',
	},
	{
		label: 'MacOS',
		value: 'macos',
	},
	{
		label: 'iOS',
		value: 'ios',
	},
	{
		label: 'Android',
		value: 'android',
	},
	{
		label: 'Linux',
		value: 'linux',
	},
	{
		label: 'Другое',
		value: 'other',
		link: ''
	},
];
